import './app.css';
import React from 'react'
import Home from "./home/Home";

function App() {

  return (
    <div className="App">
      <Home/>
    </div>
  );
}

export default App;
